import React, {useState} from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import Img from "gatsby-image"
import LightboxController from "../components/lightbox/controller";
import Quotes from "../components/quotes"
//import Timeline from "../components/timeline/timeline"
import AboutTableBox from "../components/about/about-tablebox"
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";
import BarExpand from "../components/img/bar-expand"
import BarExpandSpan from "../components/img/bar-expand-span"
import ChronoTimeline from "../components/timeline/chrono-timeline"

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cG9zdDoxMg==") {
        content
        title
        acf_page_meta {
          metatitle
          metadescription
        }
        featuredImage {
          node {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(quality: 80, maxWidth:1900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
          acf_about_fields {
            ourStoryImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
            successHead
            successItems {
              subhead
              text
            }
            philosophyItems {
              subhead
              description
            }
            philosophyImages {
              philosophyImage {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
}
`
const About = ({ data, location }) => {
  const [on, toggle] = useState(false);

    const fadein = useSpring({
        opacity: on ? 1 : 0,
        from: { opacity: 0, transform: "translate3d(0,50px,0)" },
        to: {
          opacity: on ? 1 : 0,
          transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)"
        },
        config: { tension: 100 }
      });

  const page = data.wpgraphql.page
  return (
    <Layout navlocation={location.pathname}>
    <SEO
          title={`${data.wpgraphql.page.acf_page_meta.metatitle}`}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
    <Waypoint
      onEnter={() => {
        if (!on) toggle(true);
      }}
    />
  
      <header>
        {page.featuredImage != null && (
          <Img
            imgStyle={{ objectPosition: 'bottom left'}}
            className="header__image"
            alt={ page.featuredImage.node.altText }
            fluid={ page.featuredImage.node.imageFile.childImageSharp.fluid }
          />
        )}

        <Container fluid={true} className="container__page">
          <Row>
            <Col md={{ offset:5, size: 7 }} lg={{ offset:6, size: 6 }} className="px-0">
            <animated.div style={fadein}>
              <h1 className="heading1 heading-reverse long-heading">
                <span>Over 3 Decades</span> of Affordable Housing Experience.
              </h1>
              </animated.div>
              <BarExpandSpan class="header--block block-right" width="103"/>
            </Col>
          </Row>
        </Container>
      </header>
      

      <Row className="d-flex bg-secondary mt-3 py-5 ourstory__card">

        <Col md={{ offset: 1, size: 10 }} lg={{ offset: 1, size: 5 }} className="text-white align-self-center my-sm-3 mx-md-3 px-md-0 px-lg-0">
          <div dangerouslySetInnerHTML={{ __html: page.content }}/>
        </Col>
        <Col md={{ offset: 1, size: 10 }} lg={{ offset: 1, size: 4 }} className="align-self-center pb-2 mx-md-3 px-md-0 px-lg-0">
          <Img
            className="strategy__hero_photo"
            alt={ page.acf_about_fields.ourStoryImage.altText }
            fluid={ page.acf_about_fields.ourStoryImage.imageFile.childImageSharp.fluid }
          />
        </Col>
      </Row>

      <Row className="default__card">
        <Col md={{ size: 12}} className="py-3">
          <BarExpand width="10" text={page.acf_about_fields.successHead} class="text-secondary" spanclass="service-header"/>
        </Col>
        <AboutTableBox/>
      </Row>

      <Row>
        <Col className="mt-3 pb-4 card__team">
          <BarExpand width="19" text="Our Team" class="text-secondary" spanclass="service-header"/>
        </Col>
      </Row>
      <Row>
        <Col>
          <LightboxController/> 
        </Col>
      </Row>

      <Row className="default__card">
        <Col md={{ size: 12}} className="py-3">
        <BarExpand width="19" text="Our Philosophy" class="text-secondary" spanclass="service-header"/>
          <Container fluid={true} className="p-0 m-0">
            <Row className="ourphilosophy__card pt-3">
              <Col md={{ offset: 2, size: 8}} lg={{offset: 1, size: 4}} >
                {page.acf_about_fields.philosophyImages.map((image, i) => (
                  <div className={`pb-2 pb-lg-5 philsophyimage${i === 1 && (` d-none d-lg-block`)}`}>
                    <Img
                      className="strategy__hero_photo"
                      alt={ image.philosophyImage.altText }
                      fluid={ image.philosophyImage.imageFile.childImageSharp.fluid }
                    />
                  </div>
                ))}
              </Col>
              <Col md={{ offset: 2, size: 8}} lg={{offset: 1, size: 5}} className="px-md-2">
                {page.acf_about_fields.philosophyItems.map((item, i) => (
                  <>
                    {i === 0 && (
                      <div className="pb-4">
                        <h3 className="text-primary">{item.subhead}</h3>
                        <p>{item.description}</p>
                      </div>
                    )}
                </>
                ))}
              {page.acf_about_fields.philosophyImages.map((image, i) => (
            <>
              {i === 1 && (
                <div className="d-lg-none pb-2">
            <Img
              className="strategy__hero_photo"
              alt={ image.philosophyImage.altText }
              fluid={ image.philosophyImage.imageFile.childImageSharp.fluid }
            />
            </div>
            )}
            </>
            ))}

              {page.acf_about_fields.philosophyItems.map((item, i) => (
                <>
                  {i > 0 && (
                    <div className="pb-4">
                <h3 className="text-primary">{item.subhead}</h3>
                <p>{item.description}</p>
                  </div>
              )}
                </>
              ))}

            </Col>
            
          
            
            </Row>
          </Container>
        </Col>
      </Row>
      

      <ChronoTimeline/>
  
      
    
      <Quotes id="cG9zdDoxOTk=" />


    </Layout>
  )
}

export default About
