import React from "react";
import { Chrono } from "react-chrono";

const ChronoTimeline = () => {
    const chronoitems = [
      {
    		title: '1985',
        cardTitle: "",
    		cardSubtitle: 'AHDC is formed with a mission to employ innovative financing strategies to build quality affordable housing.',
        media: ""
    	},
    	{
    		title: '1990',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC breaks ground on its first affordable housing rental community in Fresno, California.',
        media: ""
    	},
    	{
    		title: '1994',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC is awarded its first 9% tax credit allocation.',
        media: ""
    	},
    	{
    		title: '2005',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC completes its 5,000th housing unit in California.',
        media: ""
    	},
    	{
    		title: '2008',
        cardTitle: "",
    		cardSubtitle:
    		'Affordable Housing Finance Magazine names AHDC among the top 30 Owner/Operators of Affordable Workforce Housing in the nation.',
        media: ""
    	},
    	{
    		title: '2009',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC begins consulting with Fresno Housing Authority on Parc Grove Commons, a multi-phase urban neighborhood revitalization providing 403 new affordable units.',
        media: ""
    	},
    	{
    		title: '2011',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC begins construction on its 50th housing community.',
        media: ""
    	},
    	{
    		title: '2013',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC begins its first RAD (Rental Assistance Demonstration) project.',
        media: ""
    	},
    	{
    		title: '2015',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC successfully negotiates a 21-property Limited Partnership Portfolio Buyout from a single investor.',
        media: ""
    	},
    	{
    		title: '2017',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC completes its first Tax Credit Resyndication transaction in Stockton, California.',
        media: ""
    	},
    	{
    		title: '2019',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC completes over $1B in total financing volume.',
        media: ""
    	},
    	{
    		title: '2020',
        cardTitle: "",
    		cardSubtitle:
    		'AHDC completes over 9,500 total housing units.',
        media: ""
    	}
    ];
 
    return (
      <div className="timeline-chrono">
        <div style={{ width: "100%", height: "500px" }}>
        <Chrono items={chronoitems} theme={{primary: "#17AC53", secondary: "red" }} />
      </div></div>
    )
  }




export default ChronoTimeline;
